import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const monoText = "font-mono sm:text-lg md:text-xl lg:text-2xl"

const Conference = ({ data }) => {
  const {
    markdownRemark: { frontmatter: conference },
  } = data

  return (
    <Layout>
      <SEO title={conference.title} />

      <header
        style={{ backgroundColor: "#3b5cad" }}
        className="px-4 sm:px-10 py-10 sm:py-16 lg:py-20 text-white"
      >
        <div className="max-w-lg mx-auto md:max-w-6xl">
          <h1 className="text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-medium leading-tight inline-block">
            Podium is proud to be a sponsor of{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="border-b-4 border-palePink"
              href={conference.url}
            >
              {conference.title} {conference.year}
            </a>
          </h1>
        </div>
      </header>
      <div
        style={{ backgroundColor: "#334e9d" }}
        className={`px-4 sm:px-10 text-white py-8 ${monoText}`}
      >
        <div className="max-w-lg mx-auto md:max-w-6xl flex flex-wrap">
          <div className="w-full sm:w-1/2">{conference.when}</div>
          <div className="w-full sm:w-1/2">{conference.location}</div>
        </div>
      </div>

      <div className="px-4 sm:px-10">
        <div className="mt-12 sm:mt-16 md:mt-20 max-w-lg mx-auto md:max-w-6xl w-full">
          <h2 className="font-medium mb-8 md:mb-12 text-2xl sm:text-3xl md:text-4xl border-b-4 border-red inline-block">
            Find us there
          </h2>
          <ul className={`flex flex-wrap ${monoText}`}>
            {conference.attendees.map(attendee => (
              <li
                key={attendee}
                className="w-1/2 md:w-1/3 lg:w-1/4 mb-2 sm:mb-3 md:mb-4"
              >
                {attendee}
              </li>
            ))}
          </ul>
          <h2 className="font-medium mb-8 md:mb-12 mt-20 text-2xl sm:text-3xl md:text-4xl border-b-4 border-red inline-block">
            Careers
          </h2>
          <div className={monoText}>
            <p>
              We're always looking for passionate developers to join our team!
              <br />
              Learn more about careers at Podium{" "}
              <a href="https://podium.com/careers">here</a>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Conference

export const pageQuery = graphql`
  query ConferenceBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        url
        year: date(formatString: "YYYY")
        when
        location
        attendees
      }
    }
  }
`
